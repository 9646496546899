import Version from 'common/Version';
import Helpers from 'common/Helpers';
import ApiHelper from 'common/ApiHelper';

import Header from 'common/interface/Header';
import Footer from 'common/interface/Footer';

import LoginController from 'common/LoginController';
import ProjectsListController from 'common/ProjectsListController';
import MapController from 'common/MapController';

class ReperagesWebapp {

	constructor() {
		// Properties
		this.controller = null;

		// Main
		document.addEventListener('DOMContentLoaded', (event) => {
			this.boot();
		});
	}

	boot() {
		console.log('Booting ReperagesWebapp');

		setTimeout(() => {
			this.setup();

			this.checkUserStatus();
		}, 100);
	}

	checkUserStatus() {
		const urlParams = new URLSearchParams(window.location.search);
		const shareKey = urlParams.get('key');

		if (shareKey) {
			ApiHelper.sendRequest(`/api.php?action=getProjectByShareKey&key=${shareKey}`)
				.then(response => {
					if (Helpers.isObject(response) && !response.error) {
						this.showMap(response.projectId, shareKey);
					} else {
						console.error('Error: Project not found or invalid share key');
						this.showLogin(); 
					}
				})
				.catch(error => {
					console.error('Error during API request:', error);
					this.showLogin();
				});

		} else {
			ApiHelper.sendRequest('/api.php?action=whoami')
				.then(response => {
					if (Helpers.isObject(response)) {
						if (Helpers.isDefined(response.username)) {
							this.showProjectsList();
						} else {
							this.showLogin();
						}
					} else {
						console.error('Response is not a valid object');
						this.showLogin();
					}
				})
				.catch(error => {
					console.error('Failed to check user status:', error);
					this.showLogin();
				});
		}
	}

	setup() {
		this.header = new Header(document.getElementsByTagName('header')[0]);

		this.footer = new Footer(document.getElementsByTagName('footer')[0]);
		this.footer.setVersion(Version);
	}

	showLogin() {
		this.header.setTitle('');

		this.controller = new LoginController(document.getElementById('main-container'));

		document.body.classList.remove('loading');
		document.body.classList.add('ready');
	}
	
	showProjectsList() {
		this.header.setTitle('');

		this.controller = new ProjectsListController(document.getElementById('main-container'));

		document.body.classList.remove('loading');
		document.body.classList.add('ready');
	}

	showMap(projectId, shareKey = null) {
		this.header.setTitle('');

		this.controller = new MapController(document.getElementById('main-container'), projectId, shareKey);

		document.body.classList.remove('loading');
		document.body.classList.add('ready');
	}

	hideLogin() {
		if (!(this.controller instanceof LoginController)) {
			return;
		}

		this.controller.terminate();
		this.controller = null;
	}

	hideProjectsList() {
		if (!(this.controller instanceof ProjectsListController)) {
			return;
		}
		
		this.controller.terminate();
		this.controller = null;
	}

	hideMap() {
		if (!(this.controller instanceof MapController)) {
			return;
		}

		this.controller.terminate();
		this.controller = null;
	}
}

export default ReperagesWebapp;
