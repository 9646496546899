import BaseController from 'common/BaseController';
import Helpers from 'common/Helpers';
import Alert from 'common/Alert';
import ApiHelper from 'common/ApiHelper';

import ProjectsListRenderer from 'common/reperages/ProjectsListRenderer';
import ProjectFormRenderer from 'common/reperages/ProjectFormRenderer';
import ProjectRenameRenderer from 'common/reperages/ProjectRenameRenderer';

class ProjectsListController extends BaseController {

    constructor(container) {
        super(container);

        this.setup();
        this.isOptionsVisible = false;
    }

    setup() {
        this.container.innerHTML = `
            <div class="inner-container">
                <div id="project-list-container"></div>
                <div id="project-form-container" class="hidden"></div>
                <div id="rename-project-form-container" class="hidden"></div>
                <div id="overlay-container"></div>
            </div>
        `;
        
        this.fetchProjects();
    }

    fetchProjects() {
        ApiHelper.sendRequest('/api.php?action=listProjects')
            .then(data => {
                if (Helpers.isObject(data)) {
                    if (Helpers.isDefined(data.error) && data.error === 'User not authenticated') {
                        app.hideProjectsList();
                        app.showLogin();
                    } else {
                        this.displayProjects(data);
                    }
                } else {
                    console.error('Response is not a valid object');
                }
            })
            .catch(ApiHelper.handleError);
    }
    
    displayProjects(projects) {
        const projectContainer = this.container.querySelector('#project-list-container');
        if (!Helpers.isDefined(this.projectsListRenderer)) {
            this.projectsListRenderer = new ProjectsListRenderer();
        }
        projectContainer.innerHTML = this.projectsListRenderer.render(projects);

        projects.forEach(project => {
            const projectElement = this.container.querySelector(`.project[data-id="${project.id}"] .info-wrapper`);
            if (projectElement) {
                projectElement.addEventListener('click', () => this.loadProject(project.id));
            }
            
            const renameToggleButton = this.container.querySelector(`.project[data-id="${project.id}"] .project-rename-toggle`);
            if (renameToggleButton) {
                renameToggleButton.addEventListener('click', () => {
                    this.displayRenameForm(project);
                });
            }

            const deleteToggleButton = this.container.querySelector(`[data-id="${project.id}"] .project-delete-toggle`);
            if (deleteToggleButton) {
                deleteToggleButton.addEventListener('click', () => {
                    this.deleteProject(project.id);
                });
            }
        });

        const createProjectButton = this.container.querySelector('#create-project-button');
        createProjectButton.addEventListener('click', () => this.showCreateProjectForm());

        const optionsProject = this.container.querySelector('#rename-project-button');
        optionsProject.addEventListener('click', () => this.projectOptions()); 

        const filterButton = this.container.querySelector('#search-project-button');
        filterButton.addEventListener('click', () => this.toggleFilterOptions());

        const filterNameInput = this.container.querySelector('#project-filter-name');
        if (filterNameInput) {
            filterNameInput.addEventListener('input', (e) => {
                this.projectsListRenderer.handleFilterChange(e.currentTarget, this.container);
            });
        }      
    }

    toggleFilterOptions() {
        const filterOptions = this.container.querySelector('.project-filters');
        const projectNameFilter = this.container.querySelector('#project-filter-name');

        if (filterOptions.classList.contains('visible')) {
            filterOptions.classList.remove('visible');
            this.projectsListRenderer.filtersOptionsOpen = false;
        } else {
            filterOptions.classList.add('visible');
            this.projectsListRenderer.filtersOptionsOpen = true;
            const container = this.container.querySelector('#projects-list-wrapper');
            ProjectsListRenderer.filterProjects(projectNameFilter.value.trim(), container);
            projectNameFilter.focus();
        }
    }

    projectOptions() {
        const optionsProject = this.container.querySelectorAll('.projects-options');
        if (this.isOptionsVisible) {
            optionsProject.forEach(option => option.classList.add('hidden'));
        } else {
            optionsProject.forEach(option => option.classList.remove('hidden'));
        }
        this.isOptionsVisible = !this.isOptionsVisible;
    }

    displayRenameForm(project) {
        const renameprojectContainer = this.container.querySelector('#rename-project-form-container');
        const overlayContainer = this.container.querySelector('#overlay-container');

        if (renameprojectContainer.classList.contains('hidden')) {
            renameprojectContainer.classList.remove('hidden');
            overlayContainer.classList.add('active');
            renameprojectContainer.innerHTML = ProjectRenameRenderer.render(project);
        } else {
            renameprojectContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        }

        overlayContainer.addEventListener('click', () => {
            renameprojectContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });

        const renameButton = this.container.querySelector('#rename-form .rename-project-button');
        const renameInput = this.container.querySelector('#rename-form input');
        const renameForm = this.container.querySelector('#rename-form');

        renameButton.addEventListener('click', () => {
            const newName = renameInput.value.trim();
            if (newName !== '') {
                const formData = new FormData();
                formData.append('projectId', project.id);
                formData.append('newName', newName);

                ApiHelper.sendRequest(`/api.php?action=renameProject`, 'POST', formData)
                    .then(response => {
                        if (response.success) {
                            Alert.message('Le Projet a été rénommer avec succès');
                            renameForm.remove();
                            app.showProjectsList();
                        } else {
                            Alert.message(`Erreur lors du renommage du projet: ${response.error}`);
                        }
                    })
                    .catch(ApiHelper.handleError);
            } else {
                Alert.message('Entrez un nom de projet valide.');
            }
        });

    }

    showCreateProjectForm() {
        const projectFormContainer = this.container.querySelector('#project-form-container');
        const overlayContainer = this.container.querySelector('#overlay-container');

        if (projectFormContainer.classList.contains('hidden')) {
            projectFormContainer.classList.remove('hidden');
            overlayContainer.classList.add('active');
            projectFormContainer.innerHTML = ProjectFormRenderer.render();
        } else {
            projectFormContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        }

        overlayContainer.addEventListener('click', () => {
            projectFormContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });

        const createProjectForm = projectFormContainer.querySelector('#create-project-form');
        createProjectForm.addEventListener('submit', (e) => {
            e.preventDefault();
            
            const formData = new FormData(createProjectForm);

            ApiHelper.sendRequest('/api.php?action=createProject', 'POST', formData)
                .then(response => {
                    if (response.success) {
                        const projectId = response.projectId;
                        if (projectId) {
                            Alert.message('Projet créé avec succès !');
                            this.loadProject(projectId);
                        }
                    } else {
                        Alert.message('Erreur lors de la création du projet : ' + response.error);
                    }
                })
                .catch(ApiHelper.handleError);
        });
    }

    deleteProject(projectId) {
        Alert.confirm('Voulez-vous vraiment supprimer ce projet ? Cette action est irréversible.', {onOk: () => {
            const formData = new FormData();
            formData.append('projectId', projectId);
            ApiHelper.sendRequest('/api.php?action=deleteProject', 'POST', formData)
                .then(response => {
                    if (response.success) {
                        Alert.message('Le projet a été supprimé avec succès.');
                        app.showProjectsList();
                    } else {
                        Alert.message(`Erreur lors de la suppression du projet: ${response.error}`);
                    }
                })
                .catch(ApiHelper.handleError);
        }});
    }
    
    loadProject(projectId) {
        app.hideProjectsList();
        app.showMap(projectId, null);
    }

    terminate() {
        this.container.innerHTML = '';

        super.terminate();
    }
}

export default ProjectsListController;
