import BaseController from 'common/BaseController';
import LoadingHelpers from 'common/LoadingHelpers';
import Alert from 'common/Alert';
import Helpers from 'common/Helpers';
import ApiHelper from 'common/ApiHelper';

import PointsListRenderer from 'common/reperages/PointsListRenderer';
import PointDetailsRenderer from 'common/reperages/PointDetailsRenderer';
import MapStylesRenderer from 'common/reperages/MapStylesRenderer';
import UserAccountRenderer from 'common/reperages/UserAccountRenderer';
import ShareLinkRenderer from 'common/reperages/ShareLinkRenderer';
import TagFormRenderer from 'common/reperages/TagFormRenderer';
import PictureFormRenderer from 'common/reperages/PictureFormRenderer';

class MapController extends BaseController {

    constructor(container, projectId, shareKey = null) {
        super(container);

        this.mapStylesRenderer = new MapStylesRenderer();

        // Contains the list of points (markers) fetched from the database.
        this.points = null;

        // Store the current project ID
        this.projectId = projectId;

        this.markers = {}; // pointId => map marker

        // Flag to determine if the distance measuring tool is active.
        this.isMeasuringDistance = false;

        // Currently selected point ID, used to highlight or focus on a specific marker.
        this.selectedPointId = null;

        // List of map styles fetched from the API, used to populate style options.
        this.mapStyles = null;

        // Reference to the current tile layer being displayed on the map.
        this.currentTileLayer = null;

        // Default map style ID to be used if no other style is selected.
        this.defaultMapStyleId = 'osm-bright';

        // ID of the currently selected map style, initialized to the default.
        this.selectedMapStyleId = this.defaultMapStyleId;

        // Reference to the Leaflet library instance.
        this.L = null;

        // Generate a unique token for API requests.
        this.token = Helpers.uuid();

        // Store map styles indexed by their URL for quick searching.
        this.mapStyleTemplateUrlsById = {};

        // Reference to the modal tab instance.
        this.modalContainer = null;

        // Indicates whether the free measurement mode is currently active.
        this.isFreeMeasuring = false;
        
        // Contains the markers (circleMarker) placed on the map at each point clicked by the user.
        this.freeMeasureMarkers = [];

        // Reference to the final static polyline representing the measured path on the map.
        this.finalMeasurePolyline = null;

        // Reference to the live path used during measurement.
        this.liveMeasurePolyline = null;

        // Flag indicating whether live measurement mode is active.
        this.isDynamicMeasurementActive = false;
        
        // Contains the user informations.
        this.responseWhoAmi = null;

        // Store the key of project.
        this.shareKey = shareKey;

        this.setup();
    }

    setup() {
        this.container.innerHTML = `
            <div class="inner-container">
                <div id="map-wrapper" class="map-wrapper">
                    ${this.shareKey ? '' : `
                        <div class="button-fixed" id="show-styles-map">
                            <span class="icomoon-map"></span>
                        </div>
                    `}
                    <div class="button-fixed" id="show-markers-info">
                        <span class="icomoon-list2"></span>
                    </div>
                    ${this.shareKey ? '' : `
                        <div class="button-fixed" id="back-to-projects-list">
                            <span class="icomoon-text" data-tooltip="Liste des projets"></span> 
                        </div>
                        <div class="button-fixed" id="whoami">
                            <span id="title">Mon compte</span>
                        </div>
                        <div class="button-fixed" id="share-link">
                            <span class="icomoon-link"></span>
                        </div>
                    `}
                    <div class="button-fixed" id="start-free-measurement" class="default">
                        <span class="icomoon-tracking"></span>
                    </div>
                    <div id="map-container"></div>
                    <div id="overlay-container"></div>
                </div>
            </div>
        `;

        // Listeners

        this.mapWrapper = this.container.querySelector('#map-wrapper');
        
        const showMarkersButton = this.container.querySelector('#show-markers-info');
        showMarkersButton.addEventListener('click', () => this.showPointsList());

        if (!this.shareKey) {
            const showStylesMap = this.container.querySelector('#show-styles-map');
            showStylesMap.addEventListener('click', () => this.showMapStyles());
            
            const projectsListButton = this.container.querySelector('#back-to-projects-list');
            projectsListButton.addEventListener('click', () => this.showProjectsList());

            const whoami = this.container.querySelector('#whoami');
            whoami.addEventListener('click', () => this.showUserAccount());
            
            const shareLink = this.container.querySelector('#share-link');
            shareLink.addEventListener('click', () => this.fetchShareLink());
        }

        const startFreeMeasurement = this.container.querySelector('#start-free-measurement');
        startFreeMeasurement.addEventListener('click', () => this.startFreeMeasurement());
        
        this.loadProject(this.projectId, this.shareKey);
        this.shareState();

        if (!this.shareKey) {
            this.whoami();
        }
    }

    whoami() {
       ApiHelper.sendRequest('/api.php?action=whoami')
        .then(response => {
            if (Helpers.isObject(response)) {
                if (Helpers.isDefined(response.username)) {
                    console.log('User found successfully:', response.username);
                    this.responseWhoAmi = response;
                } else {
                    console.error('User not found');
                }
            } else {
                console.error('Response is not a valid object');
            }
        })
        .catch(ApiHelper.handleError);
    }

    fetchShareLink() {
        ApiHelper.sendRequest(`/api.php?action=getShareLink&projectId=${this.projectId}`)
            .then(response => {
                if (Helpers.isObject(response)) {
                    if (Helpers.isDefined(response.shareLink)) {
                        this.showShareLink(response.shareLink);
                    } else {
                        console.error('Error retrieving share link:', response);
                    }
                } else {
                    console.error('Response is not a valid object');
                }
            })
            .catch(ApiHelper.handleError);
    }

    handleLogout() {
        ApiHelper.sendRequest('/api.php?action=logout', 'POST', null)
            .then(response => {
                if (Helpers.isObject(response)) {
                    if (Helpers.isDefined(response.success)) {
                        if (response.success) {
                            console.log('Logout successful', response);
                            app.hideMap();
                            app.showLogin();
                        } else {
                            console.error('Logout failed:', response.error);
                        }
                    } else {
                        console.error('Property "success" is missing in the response');
                    }
                } else {
                    console.error('Response is not a valid object');
                }
            })
            .catch(ApiHelper.handleError);
    }

    showProjectsList() {
        app.hideMap();
        app.showProjectsList();
    }

    async loadProject(projectId, shareKey = null) {
        try {
            const data = await this.fetchProjectData(projectId, shareKey);
            this.points = data.markers;
            this.selectedMapStyleId = data.map_style_id;
            await this.initializeMap(data); 
        } catch (error) {
            console.error('Error loading project data:', error);
        }
    }

    fetchProjectData(projectId, shareKey = null) {
        let url = `/api.php?action=getProject&id=${projectId}`;
        if (shareKey) {
            url += `&key=${shareKey}`;
        }

        return ApiHelper.sendRequest(url)
            .then(data => {
                if (Helpers.isDefined(data.error) && data.error === 'User not authenticated') {
                    console.error('User not authenticated, redirecting to login');
                    app.hideMap();
                    app.showLogin();
                    throw new Error('User not authenticated');
                } else {
                    return data;
                }
            })
            .catch(ApiHelper.handleError);
    }

    getFirstMarker() {
        if (this.points && this.points.length > 0) {
            return this.points[0];
        } else {
            return null;
        }
    }

    async initializeMap(data) {
        if (!data.markers) {
            console.error('No marker data found');
            return;
        }

        try {
            const firstMarker = this.getFirstMarker();
            
            const L = await LoadingHelpers.leaflet();
            this.L = L;

            const mapContainer = this.container.querySelector('#map-container');
            const map = L.map(mapContainer, {
                zoomControl: false,
                minZoom: 1,
                maxZoom: 20
            }).setView([parseFloat(firstMarker.latitude), parseFloat(firstMarker.longitude)], 15);

            const mapStyleUrl = `https://api.equest.fr/maps/${this.selectedMapStyleId}/{z}/{x}/{y}@2x.png?token=${this.token}`;
            this.currentTileLayer = L.tileLayer(mapStyleUrl, {
                minZoom: 1,
                maxZoom: 20
            }).addTo(map);

            L.control.zoom({
                position: 'topright'
            }).addTo(map);

            this.map = map;
            this.addMarkers(map, L);

            this.map.invalidateSize();
            this.setupMapListeners();
            this.setupUserAccountContainer();
            this.setupShareLinkContainer();

        } catch (error) {
            console.error('Error initializing map:', error);
        }
    }

    setupMapListeners() {
        this.map.on('click', () => {
            this.handleMapClick();
        });

        this.map.contextmenu.addHooks();

        this.setupPointsList();
        this.setupMapStyles();
    }

    setupPointsList() {
        const pointsListContainer = document.createElement('div');
        pointsListContainer.id = 'points-list-container';
        if (!Helpers.isDefined(this.pointsListRenderer)) {
            this.pointsListRenderer = new PointsListRenderer();
        }

        pointsListContainer.innerHTML = this.pointsListRenderer.render(this.points);
        this.mapWrapper.appendChild(pointsListContainer);

        const points = pointsListContainer.querySelectorAll('.point');
        points.forEach(point => {
            point.addEventListener('click', (e) => {
                if (e.target.closest('#pin-display-toggle') !== null) {
                    // Preventing click on point interferring with click on marker (see below)
                    return;
                }

                const id = e.currentTarget.getAttribute('data-id');
                this.displayMarkerInfo(id);
                this.toggleMarkerDragging(this.markers[id], true);
            });

            point.querySelector('#pin-display-toggle').addEventListener('click', (e) => {
                const point = e.currentTarget.closest('.point');
                const pointId = point.getAttribute('data-id');
                const isSelected = e.currentTarget.classList.contains('displayed');

                if (isSelected) {
                    e.currentTarget.classList.remove('displayed');
                    this.toggleMarker(pointId, false);
                } else {
                    e.currentTarget.classList.add('displayed');
                    this.toggleMarker(pointId, true);
                }
            });

            if (this.selectedPointId !== null) {
                this.updatePinList(this.selectedPointId);
            }
        });

        const selectAllMarkers = this.container.querySelector('#select-deselect-buttons .multiple-select-all');
        const deselectAllMarkers = this.container.querySelector('#select-deselect-buttons .multiple-select-none');

        selectAllMarkers.addEventListener('click', () => {
            points.forEach(point => {
                const pointId = point.getAttribute('data-id');
                point.querySelector('#pin-display-toggle').classList.add('displayed');
                this.toggleMarker(pointId, true);
            });
        });

        deselectAllMarkers.addEventListener('click', () => {
            points.forEach(point => {
                const pointId = point.getAttribute('data-id');
                point.querySelector('#pin-display-toggle').classList.remove('displayed');
                this.toggleMarker(pointId, false);
            });
        });

        const optionsPoint = this.container.querySelector('#point-show-filter');
        optionsPoint.addEventListener('click', () => this.pointShowFilter());

        const filterButton = this.container.querySelector('#point-search-filter');
        filterButton.addEventListener('click', () => this.toggleFilterOptions());

        const filterNameFilters = this.container.querySelector('#point-filter-name');
        if (filterNameFilters) {
            filterNameFilters.addEventListener('input', (e) => {
                this.pointsListRenderer.handleFilterChange(e.currentTarget, this.container);
            });
        }
    }

    setupMapStyles() {
        const mapStylesParentContainer = document.createElement('div');
        mapStylesParentContainer.id = 'map-styles-parent-container';
        mapStylesParentContainer.classList.add('hidden');

        const mapStylesContainer = document.createElement('div');
        if (!Helpers.isDefined(this.mapStylesRenderer)) {
            this.mapStylesRenderer = new MapStylesRenderer();
        }
        mapStylesContainer.id = 'map-styles-container';
        mapStylesContainer.classList.add('hidden');
        mapStylesParentContainer.appendChild(mapStylesContainer);

        const arrowContainer = document.createElement('div');
        arrowContainer.classList.add('hidden');
        arrowContainer.id = 'map-styles-arrow';
        mapStylesParentContainer.appendChild(arrowContainer);

        this.mapWrapper.appendChild(mapStylesParentContainer);
    }

    setupUserAccountContainer() {
        const userAccountParentContainer = document.createElement('div');
        userAccountParentContainer.id = 'user-account-parent-container';
        userAccountParentContainer.classList.add('hidden');

        const userAccountContainer = document.createElement('div');
        if (!Helpers.isDefined(this.userAccountRenderer)) {
            this.userAccountRenderer = new UserAccountRenderer();
        }
        userAccountContainer.id = 'user-account-container';
        userAccountContainer.classList.add('hidden');
        userAccountParentContainer.appendChild(userAccountContainer);

        this.mapWrapper.appendChild(userAccountParentContainer);
    }

    setupShareLinkContainer() {
        const shareLinkParentContainer = document.createElement('div');
        shareLinkParentContainer.id = 'share-link-parent-container';
        shareLinkParentContainer.classList.add('hidden');

        const shareLinkContainer = document.createElement('div');
        if (!Helpers.isDefined(this.shareLinkRenderer)) {
            this.shareLinkRenderer = new ShareLinkRenderer();
        }

        shareLinkContainer.id = 'share-link-container';
        shareLinkContainer.classList.add('hidden');
        shareLinkParentContainer.appendChild(shareLinkContainer);

        this.mapWrapper.appendChild(shareLinkParentContainer);
    }

    setupUserLogout() {
        const logoutContainer = this.container.querySelector('#user-logout');
        logoutContainer.addEventListener('click', () => this.handleLogout());
    }

    calculateDistanceBetweenMarkers(marker1, marker2) {
        const latLng1 = marker1.getLatLng();
        const latLng2 = marker2.getLatLng();
        const distance = this.map.distance(latLng1, latLng2);

        return distance;
    }

    createMarker(L, point) {
        const CustomDivIcon = L.Icon.extend({
            options: {
                iconUrl: '',
                shadowUrl: null,
                iconSize: new L.Point(40, 40),
                iconAnchor: new L.Point(20, 40),
                className: '',
                classMarker: ''
            },

            createIcon: function () {
                const div = document.createElement('div');
                div.innerHTML = '<div class="marker ' + this.options.classMarker + '"></div>';
                this._setIconStyles(div, 'icon');
                return div;
            },

            createShadow: function () {
                return null;
            }
        });

        return new L.Marker([point.latitude, point.longitude], {
            icon: new CustomDivIcon({ classMarker: point.marker }),
            id: point.id,
            draggable: false,
            contextmenu: true,
            contextmenuWidth: 140,
            contextmenuItems: [{
                text: 'Mesurer la distance',
                callback: () => {
                    this.startDistanceMeasurement(this.markers[point.id]);
                }
            }],
        });
    }

    addMarkers(map, L) {
        const data = this.points;
        if (!data) {
            return;
        }

        data.forEach(point => {
            const marker = this.createMarker(L, point);
            marker.isDraggable = false;
            marker.off('click');
            marker.on('click', () => {
                this.markerOnClick(point, marker, L);
            });

            this.markers[point.id] = marker;
            map.addLayer(marker);
        });
    }

    markerOnClick(point, marker, L) {
        this.selectedPointId = point.id;

        if (this.isMeasuringDistance === true) {
            const distance = this.calculateDistanceBetweenMarkers(this.marker1, marker);
            const distanceMessage = this.container.querySelector('#distance-message .content-distance');
            distanceMessage.innerHTML = 'Distance : ' + distance.toFixed(0) + ' m';

            if (Helpers.isDefined(this.selectedMarker)) {
                this.map.removeLayer(this.selectedMarker);
            }
            this.selectedMarker = LoadingHelpers.L.circleMarker(marker.getLatLng(), { radius: 5, color: 'red' }).addTo(this.map);

            if (Helpers.isDefined(this.distancePolyline)) {
                this.map.removeLayer(this.distancePolyline);
            }
            this.distancePolyline = L.polyline([this.marker1.getLatLng(), marker.getLatLng()], { color: 'red' }).addTo(this.map);

        } else {
            this.displayMarkerInfo(marker.options.id);
            this.toggleMarkerDragging(marker, true);
        }
    }

    toggleMarkerDragging(marker, enable) {
        if (enable) {
            marker.isDraggable = true;
            marker.dragging.enable();
            marker.on('dragstart', () => {
                marker.off('click'); 
                this.map.off('click');
            });

            marker.on('dragend', () => {
                const newLatLng = marker.getLatLng();
                this.updateMarkerPosition(marker.options.id, newLatLng);
                
                setTimeout(() => {
                    marker.on('click', () => {
                        this.markerOnClick(this.points.find(p => p.id === marker.options.id), marker);
                    });
                    this.map.on('click', () => {
                        this.handleMapClick();
                    });
                }, 1.0);
            });

        } else {
            marker.isDraggable = false;
            marker.dragging.disable();
            marker.off('dragstart');
            marker.off('dragend');
        }
    }

    updatePointDetails(markerId, property, value) {
        const formData = new FormData();
        formData.append('markerId', markerId);
        formData.append('property', property);
        formData.append('value', value);

        ApiHelper.sendRequest('/api.php?action=updatePointDetails', 'POST', formData)
            .then(response => {
                if (response.success) {
                    const updatedPoint = this.points.find(point => point.id === markerId);
                    if (updatedPoint) {
                        updatedPoint[property] = value;
                    }

                    this.updatePointDisplay(markerId, updatedPoint);

                    if (property === 'marker') {
                        const markerIcon = this.markers[markerId]._icon;
                        if (markerIcon) {
                            markerIcon.querySelector('.marker').className = `marker ${updatedPoint.marker}`;
                        }
                    }
                } else {
                    Alert.message(`Erreur de mise à jour ${property} : ${response.error}`);
                }
            })
            .catch(ApiHelper.handleError);
    }

    setupPointDetailEditing () {
        const pointDetailsWrapper = this.container.querySelector('#points-details-wrapper');
        if (!pointDetailsWrapper) {
            return;
        }

        const nameInput = pointDetailsWrapper.querySelector('.name');
        if (nameInput) {
            nameInput.addEventListener('change', (e) => { 
                const newName = e.target.value.trim();
                const markerId = e.target.getAttribute('data-marker-id');

                if (newName && markerId) {
                    this.updatePointDetails(markerId, 'name', newName);
                }
            });
        }

        const pinListItems = pointDetailsWrapper.querySelectorAll('.pins-list li');
        if (pinListItems) {
            pinListItems.forEach((pinItem) => {
                pinItem.addEventListener('click', (e) => {
                    const selectedPin = e.currentTarget.getAttribute('data-pin');
                    const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');
                    if (selectedPin && markerId) {
                        this.updatePointDetails(markerId, 'marker', selectedPin);
                        this.updateSelectedPinInList(selectedPin);
                    }
                });
            });
        }

        const notesArea = pointDetailsWrapper.querySelector('.notes');
        if (notesArea) {
            notesArea.addEventListener('change', (e) => {
                const newNotes = e.target.value.trim();
                const markerId = e.target.getAttribute('data-marker-id');

                if (newNotes && markerId) {
                    this.updatePointDetails(markerId, 'notes', newNotes);
                }
            });
        }

        if (!this.shareKey) {
            const tags = pointDetailsWrapper.querySelectorAll('.tags-list .tag');
            tags.forEach(tag => {
                tag.addEventListener('click', (e) => {
                    const selectedTag = e.currentTarget.getAttribute('data-tag');
                    const isSelected = e.currentTarget.classList.contains('selected');
                    const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');

                    let currentTags = this.points.find(point => point.id === markerId).tags;

                    if (isSelected) {
                        currentTags = currentTags.split(',').filter(tag => tag !== selectedTag).join(',');
                        e.currentTarget.classList.remove('selected');
                    } else {
                        currentTags = currentTags ? currentTags + ',' + selectedTag : selectedTag;
                        e.currentTarget.classList.add('selected');
                    }
                    if (selectedTag && markerId) {
                        this.updatePointDetails(markerId, 'tags', currentTags);
                    }
                });
            });

            const addTagButton = pointDetailsWrapper.querySelector('#action-tag');
            addTagButton.addEventListener('click', () => {
                const tagFormParentContainer = this.container.querySelector('#tag-form-parent-container');
                if (tagFormParentContainer.classList.contains('hidden')) {
                    this.showTagForm();
                } else {
                    this.hideTagForm();
                }
            });

            const addPicturesButton = pointDetailsWrapper.querySelector('#action-picture');
            addPicturesButton.addEventListener('click', () => {
                const pictureFormParentContainer = this.container.querySelector('#picture-form-parent-container');
                if (pictureFormParentContainer.classList.contains('hidden')) {
                    this.showPictureForm();
                } else {
                    this.hidePictureForm();
                }
            });
        }
    }

    setupTagForm() {
        const existingtTagForm = this.container.querySelector('#tag-form-parent-container');
        if (existingtTagForm) {
            existingtTagForm.remove();
        }

        const tagFormParentContainer = document.createElement('div');
        tagFormParentContainer.id = 'tag-form-parent-container';
        tagFormParentContainer.classList.add('hidden');

        const arrowContainer = document.createElement('div');
        arrowContainer.classList.add('hidden');
        arrowContainer.id = 'tag-form-arrow';

        tagFormParentContainer.appendChild(arrowContainer);

        const tagFormContainer = document.createElement('div');
        tagFormContainer.id = 'tag-form-container';
        tagFormParentContainer.appendChild(tagFormContainer);

        this.mapWrapper.appendChild(tagFormParentContainer);
    }

    showTagForm() {
        const pointDetailsWrapper = this.container.querySelector('#points-details-wrapper');
        const tagButton = this.container.querySelector('#action-tag');
        const tagFormParentContainer = this.container.querySelector('#tag-form-parent-container');
        const tagFormContainer = this.container.querySelector('#tag-form-container');
        const arrowContainer = this.container.querySelector('#tag-form-arrow');
        const overlayContainer = this.container.querySelector('#overlay-container');

        tagFormParentContainer.classList.remove('hidden');
        arrowContainer.classList.remove('hidden');
        overlayContainer.classList.add('active');
        tagFormContainer.innerHTML = TagFormRenderer.render();

        pointDetailsWrapper.addEventListener('scroll', () => {
            if (!tagFormParentContainer.classList.contains('hidden')) {
                tagFormParentContainer.classList.add('hidden');
            }
        });

        const tagButtonRect = tagButton.getBoundingClientRect();
        tagFormParentContainer.style.top = `${tagButtonRect.top - tagFormParentContainer.offsetHeight - arrowContainer.offsetHeight}px`;
        tagFormParentContainer.style.left = `${tagButtonRect.right - tagFormParentContainer.offsetWidth}px`;

        overlayContainer.addEventListener('click', () => {
            tagFormParentContainer.classList.add('hidden');
            arrowContainer.classList.add('hidden');
            tagFormContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });

        const tagSubmitButton = this.container.querySelector('#action-new-tag');
        tagSubmitButton.addEventListener('click', () => {
            const newTagInput = this.container.querySelector('#new-tag');
            const newTag = newTagInput.value.trim();

            if (newTag) {
                const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');
                let currentTags = this.points.find(point => point.id === markerId).tags;

                const tagArray = currentTags.split(',');
                if (!tagArray.includes(newTag)) {
                    currentTags = currentTags ? currentTags + ',' + newTag : newTag;

                    this.updatePointDetails(markerId, 'tags', currentTags);
                    this.updateOtherTags(markerId, currentTags);
                    this.tagsListeners();
                    tagFormParentContainer.classList.add('hidden');
                    newTagInput.value = '';
                }
            }
        });
    }

    hideTagForm() {
        const tagFormParentContainer = this.container.querySelector('#tag-form-parent-container');
        const arrowContainer = this.container.querySelector('#tag-form-arrow');
        const overlayContainer = this.container.querySelector('#overlay-container');

        tagFormParentContainer.classList.add('hidden');
        arrowContainer.classList.add('hidden');
        overlayContainer.classList.remove('active');
    }

    setupPictureForm() {
        const existingtPictureForm = this.container.querySelector('#picture-form-parent-container');
        if (existingtPictureForm) {
            existingtPictureForm.remove();
        }

        const pictureFormParentContainer = document.createElement('div');
        pictureFormParentContainer.id = 'picture-form-parent-container';
        pictureFormParentContainer.classList.add('hidden');

        const arrowContainer = document.createElement('div');
        arrowContainer.classList.add('hidden');
        arrowContainer.id = 'picture-form-arrow';

        pictureFormParentContainer.appendChild(arrowContainer);

        const pictureFormContainer = document.createElement('div');
        pictureFormContainer.id = 'picture-form-container';
        pictureFormParentContainer.appendChild(pictureFormContainer);

        this.mapWrapper.appendChild(pictureFormParentContainer);
    }

    showPictureForm() {
        const pointDetailsWrapper = this.container.querySelector('#points-details-wrapper');
        const pictureButton = this.container.querySelector('#action-picture');
        const pictureFormParentContainer = this.container.querySelector('#picture-form-parent-container');
        const pictureFormContainer = this.container.querySelector('#picture-form-container');
        const arrowContainer = this.container.querySelector('#picture-form-arrow');
        const overlayContainer = this.container.querySelector('#overlay-container');

        pictureFormParentContainer.classList.remove('hidden');
        arrowContainer.classList.remove('hidden');
        overlayContainer.classList.add('active');
        pictureFormContainer.innerHTML = PictureFormRenderer.render();

        pointDetailsWrapper.addEventListener('scroll', () => {
            if (!pictureFormParentContainer.classList.contains('hidden')) {
                pictureFormParentContainer.classList.add('hidden');
            }
        });

        const pictureButtonRect = pictureButton.getBoundingClientRect();
        pictureFormParentContainer.style.top = `${pictureButtonRect.top - pictureFormParentContainer.offsetHeight - arrowContainer.offsetHeight}px`;
        pictureFormParentContainer.style.left = `${pictureButtonRect.right - pictureFormParentContainer.offsetWidth}px`;

        overlayContainer.addEventListener('click', () => {
            pictureFormParentContainer.classList.add('hidden');
            arrowContainer.classList.add('hidden');
            pictureFormContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });

        const submitPictureButton = this.container.querySelector('#action-new-picture');
        submitPictureButton.addEventListener('click', (e) => {
            e.preventDefault();
            const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');
            const fileInput = this.container.querySelector('#picture-file-input');
            const files = fileInput.files;
            if (files.length > 0) {
                this.uploadPictures(markerId, files);
                pictureFormParentContainer.classList.add('hidden');
                fileInput.value = '';
            }
        });
    }

    hidePictureForm() {
        const pictureFormParentContainer = this.container.querySelector('#picture-form-parent-container');
        const arrowContainer = this.container.querySelector('#picture-form-arrow');
        const overlayContainer = this.container.querySelector('#overlay-container');

        pictureFormParentContainer.classList.add('hidden');
        arrowContainer.classList.add('hidden');
        overlayContainer.classList.remove('active');
    }

    updateSelectedPinInList(selectedPin) {
        const pinListItems = this.container.querySelectorAll('.pins-list li');
        pinListItems.forEach(item => {
            item.classList.remove('selected');
        });

        const selectedPinItem = Array.from(pinListItems).find(item => item.getAttribute('data-pin') === selectedPin);
        if (selectedPinItem) {
            selectedPinItem.classList.add('selected');
        }
    }

    updateOtherTags(markerId, tags) {
        const otherTagsContainer = this.container.querySelector('#points-details-wrapper .other-tags-list');
        otherTagsContainer.innerHTML = PointDetailsRenderer.renderOtherTags(tags);
    }

    updatePointDisplay(markerId, updatedPoint) {
        const point = this.container.querySelector(`#points-list-wrapper .point[data-id="${markerId}"]`);
        if (point) {
            const infoWrapper = point.querySelector('.info-wrapper');

            const newInfoWrapper = document.createElement('div');
            newInfoWrapper.classList.add('info-wrapper');
            newInfoWrapper.innerHTML = PointsListRenderer.renderInfoWrapper(updatedPoint);

            infoWrapper.replaceWith(newInfoWrapper);
        }
    }

    uploadPictures(markerId, files) {
        if (!markerId) {
            console.error("Marker ID is missing");
            return;
        }
        const formData = new FormData();
        formData.append('markerId', markerId);
        formData.append('property', 'pictures');

        for (const file of files) {
            formData.append('pictures[]', file);
        }

        ApiHelper.sendRequest('/api.php?action=updatePointDetails', 'POST', formData)
            .then(response => {
                if (response.success) {
                    const updatedPoint = this.points.find(point => point.id === markerId);
                    if (updatedPoint) {
                        const existingPictures = updatedPoint.pictures?.split("\n") || [];
                        const newPictures = response.data || [];
                        updatedPoint.pictures = existingPictures.concat(newPictures).join("\n");
                        
                        this.updatePictures(markerId, updatedPoint.pictures, this.shareKey);
                        this.picturesListeners();
                    }
                } else {
                    Alert.message(`Erreur lors de l'ajout des photos : ${response.error}`);
                }
            })
            .catch(ApiHelper.handleError);
    }

    updatePictures(markerId, pictures) {
        const pictureList = this.container.querySelector('#points-details-wrapper .items');
        pictureList.innerHTML = PointDetailsRenderer.renderPictures(pictures, this.projectId, this.shareKey);
    }

    deletePicture(markerId, pictureName) {
        const formData = new FormData();
        formData.append('markerId', markerId);
        formData.append('property', 'pictures');
        formData.append('pictureName', pictureName);

        Alert.confirm('Etes-vous sûr de vouloir supprimer cette photo ?', {onOk: () => {
            ApiHelper.sendRequest('/api.php?action=deletePicture', 'POST', formData)
                .then(response => {
                    if (response.success) {
                        const updatedPoint = this.points.find(point => point.id === markerId);
                        if (updatedPoint) {
                            const existingPictures = updatedPoint.pictures?.split("\n") || [];
                            const updatedPictures = existingPictures.filter(name => name !== pictureName);
                            updatedPoint.pictures = updatedPictures.join("\n");

                            this.updatePictures(markerId, updatedPoint.pictures, this.shareKey);
                            this.picturesListeners();
                        }

                        this.closePictureModal();

                    } else {
                        Alert.message(`Erreur lors de la suppression de la photo : ${response.error}`);
                    }
                })
                .catch(ApiHelper.handleError);
        }});
    }

    updateMarkerPosition(markerId, newLatLng) {
        const formData = new FormData();
        formData.append('markerId', markerId);
        formData.append('property', 'coordinates');
        formData.append('latitude', newLatLng.lat.toFixed(6));
        formData.append('longitude', newLatLng.lng.toFixed(6));

        ApiHelper.sendRequest('/api.php?action=updatePointDetails', 'POST', formData)
            .then(response => {
                if (response.success) {
                    const updatedPoint = this.points.find(point => point.id === markerId);
                    if (updatedPoint) {
                        updatedPoint.latitude = newLatLng.lat;
                        updatedPoint.longitude = newLatLng.lng;
                    }
                    
                    this.updatePointDisplay(markerId, updatedPoint);
                    this.updateMarkerCoordinatesInDetails(markerId, newLatLng);
                } else {
                    Alert.message(`Erreur de mise à jour des coordonnées : ${response.error}`);
                }
            })
            .catch(ApiHelper.handleError);
    }

    updateMarkerCoordinatesInDetails(markerId, newLatLng) {
        const pointDetailsContainer = this.container.querySelector('#point-details-container');
        if (pointDetailsContainer) {
            const latitude = pointDetailsContainer.querySelector('.point-details-location .latitude .value');
            const longitude = pointDetailsContainer.querySelector('.point-details-location .longitude .value');
            const googleMapsLink = pointDetailsContainer.querySelector('.point-details-location .actions a.open-in-maps');
            
            if (latitude && longitude && googleMapsLink) {
                latitude.innerHTML = `${newLatLng.lat.toFixed(6)}`;
                longitude.innerHTML = `${newLatLng.lng.toFixed(6)}`;
                googleMapsLink.href = `https://www.google.com/maps?q=${newLatLng.lat.toFixed(6)},${newLatLng.lng.toFixed(6)}`;
            }
        }
    }

    handleMapClick() {
        const pointDetailsContainer = this.container.querySelector('#point-details-container');
        if (pointDetailsContainer) {
            pointDetailsContainer.remove();
        }

        const selectedDivPoint = this.container.querySelector('#points-list-container .point.selected');
        if (selectedDivPoint) {
            selectedDivPoint.classList.remove('selected');
        }

        this.map.eachLayer(layer => {
            if (layer instanceof LoadingHelpers.L.Marker) {
                layer.setOpacity(1);
                layer.setZIndexOffset(0);
                this.toggleMarkerDragging(layer, false);
            }
        });

        this.selectedPointId = null;
    }
    
    calculateTotalDistance() {
        if (this.freeMeasureMarkers.length < 2) {
            return 0; // Not enough points to calculate a distance
        }

        let totalDistance = 0;
        for (let i = 0; i < this.freeMeasureMarkers.length - 1; i++) {
            const latLng1 = this.freeMeasureMarkers[i].getLatLng();
            const latLng2 = this.freeMeasureMarkers[i + 1].getLatLng();
            totalDistance += this.map.distance(latLng1, latLng2);
        }

        return totalDistance;
    }

    startFreeMeasurement() {
        let distanceMessage = this.container.querySelector('#free-distance-message');
        if (distanceMessage === null) {
            distanceMessage = document.createElement('div');
            distanceMessage.id = 'free-distance-message';
            distanceMessage.innerHTML = `
                <div class="title">Mesurer la distance</div>
                <span class="icomoon-cross2 close"></span>
                <div class="content">Cliquez sur la carte pour placer des marqueurs.</div>
                <div class="content-distance"></div>
                <div class="options">
                    <div class="option-remove-marker hidden offset-left">
                        <span class="icomoon-undo" id="remove-last-marker" data-tooltip="Enlever le dernier marqueur"></span>
                    </div>
                    <div class="option-measure-control option-stop-measure hidden">
                        <span class="icomoon-stop" id="stop-dynamic-polyline" data-tooltip="Terminer la distance"></span>
                    </div>
                    <div class="option-measure-control option-resume-measure hidden">
                        <span class="icomoon-record" id="resume-dynamic-polyline" data-tooltip="Reprendre la mesure"></span>
                    </div>
                </div>
            `;
            this.mapWrapper.appendChild(distanceMessage);

            this.removeLastMarker();
            this.removeDynamicPolyline();
            this.resumeDynamicPolyline();
        }

        this.isFreeMeasuring = true;

        const handleFreeMeasureClick = (e) => this.handleFreeMeasureClick(e);
        this.map.on('click', handleFreeMeasureClick);

        const handleMouseMove = (e) => this.handleMouseMove(e);
        this.map.on('mousemove', handleMouseMove);

        distanceMessage.querySelector('.close').addEventListener('click', () => {
            this.isFreeMeasuring = false;
            this.map.off('click', handleFreeMeasureClick);

            distanceMessage.remove();

            if (this.finalMeasurePolyline !== null) {
                this.finalMeasurePolyline.remove();
                this.finalMeasurePolyline = null;
            }

            if (this.liveMeasurePolyline !== null) {
                this.liveMeasurePolyline.remove();
                this.liveMeasurePolyline = null;
            }

            this.freeMeasureMarkers.forEach(marker => marker.remove());
            this.freeMeasureMarkers = [];
            
            this.map.off('mousemove', handleMouseMove);
        });
    }

    handleFreeMeasureClick(e) {
        if (this.isFreeMeasuring === false) {
            return;
        }

        const latLng = e.latlng;
        const marker = LoadingHelpers.L.circleMarker(latLng, { radius: 5, color: 'red' }).addTo(this.map);
        this.freeMeasureMarkers.push(marker);

        this.updatePolylineAndDistance();

        const removeLastMarker = this.container.querySelector('.options .option-remove-marker');
        if (removeLastMarker) {
            removeLastMarker.classList.remove('hidden');
        }

        const removeDynamicPolyline = this.container.querySelector('.options .option-stop-measure');
        if (removeDynamicPolyline) {
            removeDynamicPolyline.classList.remove('hidden');
        }
    }

    updatePolylineAndDistance() {
        const latLngs = this.freeMeasureMarkers.map(marker => marker.getLatLng());

        if (this.freeMeasureMarkers.length > 0) {
            if (this.finalMeasurePolyline !== null) {
                this.finalMeasurePolyline.setLatLngs(latLngs);
            } else {
                this.finalMeasurePolyline = LoadingHelpers.L.polyline(latLngs, { color: 'red' }).addTo(this.map);
            }
        }

        const totalDistance = this.calculateTotalDistance();
        const distanceMessage = this.container.querySelector('#free-distance-message .content-distance');
        if (distanceMessage) {
            distanceMessage.innerHTML = `Distance totale: ${totalDistance.toFixed(0)} m`;
        } 
    }

    removeLastMarker() {
        const removeLastMarker = this.container.querySelector('.options .option-remove-marker');
        const handleMouseMove = (e) => this.handleMouseMove(e);
        
        if (Helpers.isDefined(removeLastMarker)) {
            removeLastMarker.addEventListener('click', () => {
                if (this.freeMeasureMarkers.length > 0) {
                    const lastMarker = this.freeMeasureMarkers.pop();
                    this.map.removeLayer(lastMarker);

                    this.updatePolylineAndDistance();

                    if (this.freeMeasureMarkers.length === 0) {
                        removeLastMarker.classList.add('hidden');
                        const removeDynamicPolyline = this.container.querySelector('.options .option-stop-measure');
                        if (removeDynamicPolyline) {
                            removeDynamicPolyline.classList.add('hidden');
                        }
                        const resumeDynamicPolyline = this.container.querySelector('.options .option-resume-measure');
                        if (resumeDynamicPolyline) {
                            resumeDynamicPolyline.classList.add('hidden');
                        }

                    } else {
                        this.map.on('mousemove', handleMouseMove);
                    }

                    if (this.liveMeasurePolyline !== null) {
                        this.liveMeasurePolyline.remove();
                        this.liveMeasurePolyline = null;
                    }
                }

                this.isDynamicMeasurementActive = false;
                this.map.off('mousemove', handleMouseMove);
            });
        }
    }

    handleMouseMove(e) {
        if (this.isFreeMeasuring === false || this.freeMeasureMarkers.length === 0) {
            return;
        }

        this.isDynamicMeasurementActive  = true;

        const latLngs = this.freeMeasureMarkers.map(function(marker) {
            return marker.getLatLng();
        });

        latLngs.push(e.latlng);

        if (this.liveMeasurePolyline !== null) {
            this.liveMeasurePolyline.setLatLngs(latLngs);
        } else {
            this.liveMeasurePolyline = LoadingHelpers.L.polyline(latLngs, {
                color: 'red',
                dashArray: '5, 10',
                className: 'polyline-dynamic'
            }).addTo(this.map);
        }

        const distanceMessage = this.container.querySelector('#free-distance-message .content-distance');
        const totalDistance = this.calculateDistanceDynamic(e.latlng);
        if (distanceMessage) {
            distanceMessage.innerHTML = `Distance totale: ${totalDistance.toFixed(0)} m`;
        }
    }

    calculateDistanceDynamic(dynamicPoint) {
        const latLngs = [];
        for (const marker of this.freeMeasureMarkers) {
            latLngs.push(marker.getLatLng());
        }
        latLngs.push(dynamicPoint);

        let totalDistance = 0;
        for (let i = 1; i < latLngs.length; i++) {
           totalDistance += this.map.distance(latLngs[i - 1], latLngs[i]);
        }

        return totalDistance;
    }

    removeDynamicPolyline() {
        const removeDynamicPolyline = this.container.querySelector('.options .option-stop-measure');
        const removeLastMarker = this.container.querySelector('.options .option-remove-marker');
        const resumeDynamicPolyline = this.container.querySelector('.options .option-resume-measure');

        if (Helpers.isDefined(removeDynamicPolyline)) {
            removeDynamicPolyline.addEventListener('click', () => {
                this.isDynamicMeasurementActive  = false;
                this.map.off('mousemove');

                if (this.liveMeasurePolyline) {
                    this.liveMeasurePolyline.remove();
                    this.liveMeasurePolyline = null;
                }
                this.updatePolylineAndDistance();
                removeDynamicPolyline.classList.add('hidden');
                removeLastMarker.classList.add('offset-left');
                resumeDynamicPolyline.classList.remove('hidden');

            });
        }
    }

    resumeDynamicPolyline() {
        const resumeDynamicPolyline = this.container.querySelector('.options .option-resume-measure');

        if (Helpers.isDefined(resumeDynamicPolyline)) {
            resumeDynamicPolyline.addEventListener('click', () => {
                this.isDynamicMeasurementActive = true;
                resumeDynamicPolyline.classList.add('hidden');
                
                const handleMouseMove = (e) => this.handleMouseMove(e);
                this.map.on('mousemove', handleMouseMove);

                const removeDynamicPolyline = this.container.querySelector('.options .option-stop-measure');
                if (removeDynamicPolyline) {
                    removeDynamicPolyline.classList.remove('hidden');
                }
            });
        }
    }

    startDistanceMeasurement(marker1) {
        let distanceMessage = this.container.querySelector('#distance-message');
        if (distanceMessage === null) {
            distanceMessage = document.createElement('div');
            distanceMessage.id = 'distance-message';
            distanceMessage.innerHTML = `
                <div class="title">Mesurer la distance</div>
                <span class="icomoon-cross2 close"></span>
                <div class="content"></div>
                <div class="content-distance"></div>
            `;
            this.mapWrapper.appendChild(distanceMessage);
        }

        this.isMeasuringDistance = true;
        this.marker1 = marker1;

        distanceMessage.querySelector('.content').innerHTML = 'Cliquez sur le deuxième marqueur pour tracer le trajet à mesurer.';

        const selectedMarker1 = LoadingHelpers.L.circleMarker(marker1.getLatLng(), { radius: 5, color: 'red' }).addTo(this.map);
        distanceMessage.querySelector('.close').addEventListener('click', () => {
            this.isMeasuringDistance = false;
            distanceMessage.remove();

            if (Helpers.isDefined(this.distancePolyline)) {
                this.distancePolyline.remove();
                delete this.distancePolyline;
            }

            if (Helpers.isDefined(this.selectedMarker)) {
                this.selectedMarker.remove();
                delete this.selectedMarker;
            }

            selectedMarker1.remove();
        });

    }

    showUserAccount() {
        const userAccountParentContainer = this.container.querySelector('#user-account-parent-container');
        const userAccountContainer = this.container.querySelector('#user-account-container');
        const overlayContainer = this.container.querySelector('#overlay-container');

        if (userAccountParentContainer.classList.contains('hidden')) {
            userAccountParentContainer.classList.remove('hidden');
            userAccountContainer.classList.remove('hidden');
            overlayContainer.classList.add('active');

            userAccountContainer.innerHTML = this.userAccountRenderer.render(this.responseWhoAmi);
            this.setupUserLogout();
        } else {
            userAccountParentContainer.classList.add('hidden');
            userAccountContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        }

        overlayContainer.addEventListener('click', () => {
            userAccountParentContainer.classList.add('hidden');
            userAccountContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });
    }

    showShareLink(shareLink) {
        const shareLinkParentContainer = this.container.querySelector('#share-link-parent-container');
        const shareLinkContainer = this.container.querySelector('#share-link-container');
        const overlayContainer = this.container.querySelector('#overlay-container');

        if (shareLinkParentContainer.classList.contains('hidden')) {
            shareLinkParentContainer.classList.remove('hidden');
            shareLinkContainer.classList.remove('hidden');
            overlayContainer.classList.add('active');

            shareLinkContainer.innerHTML = this.shareLinkRenderer.render(shareLink);
            this.setupCopyLink(shareLink);

        } else {
            shareLinkParentContainer.classList.add('hidden');
            shareLinkContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        }

        overlayContainer.addEventListener('click', () => {
            shareLinkParentContainer.classList.add('hidden');
            shareLinkContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });
    }

    setupCopyLink(shareLink) {
        const copyLinkContainer = this.container.querySelector('#share-copy-link-message');
        copyLinkContainer.addEventListener('click', () => {
            this.shareLinkRenderer.copyToClipboard(shareLink);
        });
    }

    shareState() {
        const startFreeMeasurementButton = this.container.querySelector('#start-free-measurement');

        if (this.shareKey) {
            startFreeMeasurementButton.classList.add('shared');
            startFreeMeasurementButton.classList.remove('default');
        } else {
            startFreeMeasurementButton.classList.add('default');
            startFreeMeasurementButton.classList.remove('shared');
        }
    }

    showMapStyles() {
        const mapStylesParentContainer = this.container.querySelector('#map-styles-parent-container');
        const mapStylesContainer = this.container.querySelector('#map-styles-container');
        const arrowContainer = this.container.querySelector('#map-styles-arrow');
        const overlayContainer = this.container.querySelector('#overlay-container');

        if (mapStylesParentContainer.classList.contains('hidden')) {
            mapStylesParentContainer.classList.remove('hidden');
            arrowContainer.classList.remove('hidden');
            mapStylesContainer.classList.remove('hidden');
            overlayContainer.classList.add('active');
            if (this.mapStyles !== null) {
                this.populateMapStyles(this.mapStyles);
            } else {
                this.fetchMapStyles();
            }
        } else {
            mapStylesParentContainer.classList.add('hidden');
            arrowContainer.classList.add('hidden');
            mapStylesContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        }

        overlayContainer.addEventListener('click', () => {
            mapStylesParentContainer.classList.add('hidden');
            arrowContainer.classList.add('hidden');
            mapStylesContainer.classList.add('hidden');
            overlayContainer.classList.remove('active');
        });
    }

    fetchMapStyles() {
        const apiUrl = `https://api.equest.fr/maps/list.json?token=${this.token}`;

        const mapStylesContainer = this.container.querySelector('#map-styles-container');
        mapStylesContainer.innerHTML = MapStylesRenderer.renderLoadingIndicator();

        const xhr = new XMLHttpRequest();
        xhr.open('GET', apiUrl, true);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    this.mapStyles = JSON.parse(xhr.responseText);
                    this.mapStyles.forEach(styleGroup => {
                        styleGroup.maps.forEach(mapStyle => {
                            this.mapStyleTemplateUrlsById[mapStyle.id] = mapStyle.templateUrl;
                        });
                    });

                    this.populateMapStyles(this.mapStyles);  
                } else {
                    console.error('Failed to fetch map styles:', xhr.status, xhr.statusText);
                    mapStylesContainer.innerHTML = '';
                    Alert.message("Veuillez vérifier votre connexion internet");
                }
            }
        };

        xhr.send();
    }

    populateMapStyles(mapStyles) {
        const mapStylesContainer = this.container.querySelector('#map-styles-container');
        mapStylesContainer.innerHTML = this.mapStylesRenderer.render(mapStyles, this.selectedMapStyleId, this.token);

        const styleItems = this.container.querySelectorAll('#map-styles-container .map-style-item');
        styleItems.forEach(item => {
            item.addEventListener('click', () => {
                const mapStyleId = item.getAttribute('data-map-style-id');
                this.changeMapStyle(mapStyleId);
            });
        });

        const filterStyleMapButton = this.container.querySelector('#map-search-filter');
        filterStyleMapButton.addEventListener('click', () => this.toggleMapStyleOptions());

        const filterStyleMap = this.container.querySelector('#map-style-search');
        if (filterStyleMap) {
            filterStyleMap.addEventListener('input', (e) => {
                const container = this.container.querySelector('#map-styles-wrapper');
                this.mapStylesRenderer.handleFilterChange(e.currentTarget, container);
            });
        }

        this.mapStylesRenderer.reapplyFilter(this.container.querySelector('#map-styles-wrapper'));

        const mapsStyleSelected = this.container.querySelector('.map-style-item.selected:not(.hidden)');
        if (mapsStyleSelected) {
            const mapStylesWrapper = this.container.querySelector('#map-styles-wrapper');
            const targetScrollTop = mapsStyleSelected.offsetTop - (mapStylesWrapper.offsetHeight / 2);
            this.scrollToElementSmoothly(targetScrollTop, 500, 'scrollTop', mapStylesWrapper, this.easeInOutCubic);
        }
    }

    changeMapStyle(mapStyleId) {
        if (this.currentTileLayer !== null) {
            this.map.removeLayer(this.currentTileLayer);
            this.currentTileLayer = null;
        }

        const templateUrl = this.mapStyleTemplateUrlsById[mapStyleId];
        if (templateUrl) {
            const tileLayerUrl = templateUrl.replace('{token}', this.token).replace('{scale}', '@2x');
            this.currentTileLayer = this.L.tileLayer(tileLayerUrl, {
                minZoom: 1,
                maxZoom: 20
            }).addTo(this.map);    
        } else {
            console.error('Template URL not found for map style:', mapStyleId);
        }

        const previousStyleMap = this.container.querySelector('.map-style-item.selected');
        if (previousStyleMap) {
            previousStyleMap.classList.remove('selected');
        }

        const newStyleMap = this.container.querySelector(`.map-style-item[data-map-style-id="${mapStyleId}"]`);
        if (newStyleMap) {
            newStyleMap.classList.add('selected');

            const mapStylesWrapper = this.container.querySelector('#map-styles-wrapper');

            if (newStyleMap.offsetTop < mapStylesWrapper.scrollTop || newStyleMap.offsetTop + newStyleMap.offsetWidth >= mapStylesWrapper.offsetHeight + mapStylesWrapper.scrollTop) {
                const targetScrollTop = newStyleMap.offsetTop - (mapStylesWrapper.offsetHeight / 2);
                this.scrollToElementSmoothly(targetScrollTop, 500, 'scrollTop', mapStylesWrapper, this.easeInOutCubic);
            }
        }

        this.selectedMapStyleId = mapStyleId;

        this.saveMapStyle(mapStyleId);
    }

    saveMapStyle(mapStyleId) {
        const formData = new FormData();
        formData.append('projectId', this.projectId);
        formData.append('mapStyleId', mapStyleId);

        ApiHelper.sendRequest('/api.php?action=saveMapStyle', 'POST', formData)
            .then(response => {
                if (!response.success) {
                    Alert.message(`Erreur lors de la mise à jour du style de carte: ${response.error}`);
                }
            })
            .catch(ApiHelper.handleError);
    }

    showPointsList() {
        const pointsListContainer = this.container.querySelector('#points-list-container');
        if (!pointsListContainer.classList.contains('active')) {
            pointsListContainer.classList.add('active');
        } else {
            pointsListContainer.classList.remove('active');
        }
    }

    // Scroll smoothly to a specific element within a wrapper element
    scrollToElementSmoothly(targetScroll, duration, property, scrollWrapper, easingFunction) {
        const startTime = performance.now();
        const startScroll = scrollWrapper[property];
        const distanceToTravel = targetScroll - startScroll;
        const lastUpdateTime = startTime;

        requestAnimationFrame(() => {
            this.stepAnimation(startTime, duration, startScroll, distanceToTravel, property, scrollWrapper, lastUpdateTime, targetScroll, easingFunction);
        });
    }

    // Animate the scrolling process by gradually updating the scroll property of the wrapper element
    stepAnimation(startTime, duration, startScroll, distanceToTravel, property, scrollWrapper, lastUpdateTime, targetScroll, easingFunction) {
        const currentTime = performance.now();
        const elapsedTime = currentTime - startTime;

        if (elapsedTime < duration) {
            const newScroll = easingFunction(elapsedTime, startScroll, distanceToTravel, duration);
            const timeSinceLastUpdate = currentTime - lastUpdateTime;

            if (timeSinceLastUpdate >= (1000 / 25) * 0.80) { // Limit the frequency of calls to requestAnimationFrame to around 25 FPS
                lastUpdateTime = currentTime;
                scrollWrapper[property] = newScroll;
            }

            requestAnimationFrame(() => {
                this.stepAnimation(startTime, duration, startScroll, distanceToTravel, property, scrollWrapper, lastUpdateTime, targetScroll, easingFunction);
            });

        } else {
            scrollWrapper[property] = targetScroll;
        }
    }

    pointShowFilter() {
        const selectDeselectButtons = this.container.querySelector('#select-deselect-buttons');
        const showPin = this.container.querySelectorAll('.options-wrapper .show-pin');
        const isButtonsVisible = !selectDeselectButtons.classList.contains('hidden');

        if (isButtonsVisible) {
            selectDeselectButtons.classList.add('hidden');
            showPin.forEach(detailsPointDiv => {
                detailsPointDiv.classList.add('hidden');
            });

        } else {
            selectDeselectButtons.classList.remove('hidden');
            showPin.forEach(detailsPointDiv => {
                detailsPointDiv.classList.remove('hidden');
            });
        }
    }

    toggleMarker(pointId, show) {
        const marker = this.markers[pointId];
        if (!Helpers.isDefined(marker)) {
            console.log('Marker for point ' + pointId + ' does not exist');
            return;
        }

        if (show) {
            this.map.addLayer(marker);
        } else {
            this.map.removeLayer(marker);
        }
    }

    toggleFilterOptions() {
        const filterOptions = this.container.querySelector('.point-filters');
        const pointNameFilter = this.container.querySelector("#point-filter-name");

        if (filterOptions.classList.contains('visible')) {
            filterOptions.classList.remove('visible');
            this.pointsListRenderer.filtersOptionsOpen = false;
        } else {
            filterOptions.classList.add('visible');
            this.pointsListRenderer.filtersOptionsOpen = true;
            const container = this.container.querySelector('#points-list-wrapper');
            PointsListRenderer.filterPoints(pointNameFilter.value.trim(), container);
             
            pointNameFilter.focus();
        }
    }

    toggleMapStyleOptions() {
        const filterOptions = this.container.querySelector('.map-style-filters');
        const mapNameFilter = this.container.querySelector("#map-style-search");
        const container = this.container.querySelector('#map-styles-wrapper');

        if (filterOptions.classList.contains('visible')) {
            filterOptions.classList.remove('visible');
            this.mapStylesRenderer.filtersOptionsOpen = false;
        } else {
            filterOptions.classList.add('visible');
            this.mapStylesRenderer.filtersOptionsOpen = true;
            MapStylesRenderer.filterStyleMap(mapNameFilter.value.trim(), container);
            this.mapStylesRenderer.reapplyFilter(container);

            mapNameFilter.focus();
        }
    }

    updatePinList(id) {
        const previouslySelectedPoint = this.container.querySelector('#points-list-container .point.selected');
        if (previouslySelectedPoint) {
            const previousId = previouslySelectedPoint.getAttribute('data-id');
            previouslySelectedPoint.classList.remove('selected');
            if (this.markers[previousId]) {
                this.toggleMarkerDragging(this.markers[previousId], false);
            }
        }

        const pointDiv = this.container.querySelector(`#points-list-container .point[data-id="${id}"]`);
        // Highlight the corresponding point in the list and ensure it's in view.
        if (pointDiv) {
            pointDiv.classList.add('selected');
            const pointListWrapper = this.container.querySelector('#points-list-wrapper');

            if (pointDiv.offsetTop < pointListWrapper.scrollTop || pointDiv.offsetTop + pointDiv.offsetWidth >= pointListWrapper.offsetHeight + pointListWrapper.scrollTop) {
                const targetScrollTop = pointDiv.offsetTop - (pointListWrapper.offsetHeight / 2);
                this.scrollToElementSmoothly(targetScrollTop, 500, 'scrollTop', pointListWrapper, this.easeInOutCubic);
            }
        }
    }

    displayMarkerInfo(id) {
        const existingPointDivInfo = this.container.querySelector('#point-details-container');
        const isEditable = !this.shareKey;

        if (this.isMeasuringDistance) {
            return;
        }

        if (existingPointDivInfo) {
            existingPointDivInfo.remove();
        }

        const pointData = this.points.find(point => point.id === id);
        if (!pointData) {
            console.error('Point data not found for id:', id);
            return;
        }

        const pointInfoDiv = document.createElement('div');
        pointInfoDiv.id = 'point-details-container';
        pointInfoDiv.classList.add('active');
        pointInfoDiv.innerHTML = PointDetailsRenderer.render(pointData, this.projectId, isEditable, this.shareKey);

        this.mapWrapper.appendChild(pointInfoDiv);

        this.setupPointDetailEditing();
        this.setupTagForm();
        this.setupPictureForm();

        this.tagsListeners();
        this.picturesListeners();

        this.updatePinList(id);

        // Updating markers
        const marker = this.markers[id];
        if (Helpers.isDefined(marker)) {
            this.map.setView(marker.getLatLng(), this.map.getZoom());

            this.map.eachLayer(layer => {
                if (layer instanceof LoadingHelpers.L.Marker) {
                    if (layer === marker) {
                        layer.setOpacity(1);
                        layer.setZIndexOffset(1000);
                    } else {
                        layer.setOpacity(0.5);
                        layer.setZIndexOffset(0);
                    }
                }
            });
        }

        const pinDetailsWrapper = this.container.querySelector('.point-details-pin-contents');
        if (pinDetailsWrapper) {
            const selectedPin = pinDetailsWrapper.querySelector('.selected'); 
            if (selectedPin.offsetLeft < pinDetailsWrapper.scrollLeft || selectedPin.offsetLeft + selectedPin.offsetWidth >= pinDetailsWrapper.offsetWidth + pinDetailsWrapper.scrollLeft) {
                pinDetailsWrapper.scrollLeft = selectedPin.offsetLeft - (pinDetailsWrapper.offsetWidth / 2);
            }
        }
    }

    tagsListeners() {
        const pointDetailsWrapper = this.container.querySelector('#points-details-wrapper');
        const otherTags = pointDetailsWrapper.querySelectorAll('.other-tags-list .tag');
        otherTags.forEach(tag => {
            tag.addEventListener('click', (e) => {
                const selectedTag = e.target.innerText.trim();
                const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');

                let currentTags = this.points.find(point => point.id === markerId).tags;

                const tagArray = currentTags.split(',');
                if (tagArray.includes(selectedTag)) {
                    currentTags = tagArray.filter(tag => tag !== selectedTag).join(',');
                    e.currentTarget.classList.add('inactive');
                } else {
                    currentTags = currentTags ? currentTags + ',' + selectedTag : selectedTag;
                    e.currentTarget.classList.remove('inactive');
                }

                this.updatePointDetails(markerId, 'tags', currentTags);
            });
        });
    }

    picturesListeners() {
        const pointDetailsWrapper = this.container.querySelector('#points-details-wrapper');
        const pictures = pointDetailsWrapper.querySelectorAll('.point-details-pictures .picture-thumbnail');
        pictures.forEach(picture => {
            const pictureName = picture.getAttribute('data-picture');
            const markerId = pointDetailsWrapper.querySelector('.name').getAttribute('data-marker-id');
            picture.addEventListener('click', () => this.openPictureModal(pictureName, markerId));
        });
    }

    openPictureModal(pictureName, markerId) {
        const pictureUrl = `/api.php?action=getPicture&name=${pictureName}&projectId=${this.projectId}&size=full${this.shareKey ? `&key=${this.shareKey}` : ''}`;
        const isEditable = !this.shareKey;

        this.modalContainer = document.createElement('div');
        this.modalContainer.id = 'picture-modal-container';
        this.modalContainer.innerHTML = PointDetailsRenderer.renderPictureModal(pictureUrl, isEditable);
        this.mapWrapper.appendChild(this.modalContainer);

        const modalClose = this.modalContainer.querySelector('.close-button');
        modalClose.addEventListener('click', () => this.closePictureModal());

        if (isEditable) {
            const deleteButton = this.container.querySelector('.delete-button');
            deleteButton.addEventListener('click', () => this.deletePicture(markerId, pictureName));
        }
    }

    closePictureModal() {
        if (this.modalContainer !== null) {
            this.modalContainer.remove();
            this.modalContainer = null;
        }
    }

    // Adjusts the progression of scrolling over time to accelerate and decelerate smoothly
    easeInOutCubic(t, b, c, d) {
        if ((t /= d / 2) < 1) {
            return (c / 2 * t * t * t) + b;
        }

        return (c / 2 * (((t -= 2) * t * t) + 2)) + b;
    }

    terminate() {
        this.container.innerHTML = '';

        super.terminate();
    }
}

export default MapController;
